import '../writing.css';
import MainHeader from '../components/main-header/MainHeader';
import Abstract from '../components/abstract/Abstract';
import TableOfContents from '../components/table-of-contents';
import ArticleHeader from '../components/article-header/ArticleHeader';
import useDocumentTitle from '../utils/useDocumentTitle';
import About from '../components/about/About';


export const Stanley = () => {
    useDocumentTitle('Stanley');
    return (
        <div className="container" style={{
            paddingBottom: '300px',
        }}>
            <About/>
            <div className="content">
                <MainHeader datetime={'21 Nov, 2022'} header="Stanley" color={'#BA0021'}/>
                <Abstract
                    text={'This is the story of a man named Stanley.'}/>
                <img
                    src={"https://media.npr.org/assets/img/2013/11/19/stanley_5_wide-9184b72f550b09ff8e48cbb9830f8266f084e098.png"}
                    alt="Stanley sitting is his room, looking to his computer" style={{width: '100%'}}/>
                <TableOfContents cssClass={'activeWritingHead'}/>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%',
                }}>
                <div className="writing">
                    <ArticleHeader header={'A Game'}
                                   id={'second-header'}/>
                    <blockquote>
                        Stanley worked for a company in a big building where he was Employee #427.
                        <br/><br/>Employee #427's job was simple: he sat at his desk in Room 427 and he pushed buttons
                        on a keyboard.
                        <br/><br/>Orders came to him through a monitor on his desk telling him what buttons to push, how
                        long to push them, and in what order.
                        <br/><br/>This is what Employee #427 did every day of every month of every year, and although
                        others may have considered it soul rending,
                        <br/><br/>Stanley relished every moment that the orders came in, as though he had been made
                        exactly for this job.
                        <br/><br/>And Stanley was happy.
                        <br/><br/>And then one day, something very peculiar happened.

                        <br/><br/> Something that would forever change Stanley;

                        <br/><br/> Something he would never quite forget.

                        <br/><br/> He had been at his desk for nearly an hour when he had realized not one single order
                        had arrived on the monitor for him to follow.

                        <br/><br/> No one had shown up to give him instructions, call a meeting, or even say 'hi'. Never
                        in all his years at the company had this happened, this complete isolation.

                        <br/><br/> Something was very clearly wrong. Shocked, frozen solid, Stanley found himself unable
                        to move for the longest time.

                        <br/><br/> But as he came to his wits and regained his senses, he got up from his desk and
                        stepped out of his office.
                    </blockquote>
                    <p>
                        This script is the beginning of the story-based video game "The Stanley Parable". In the game,
                        the Narrator (the creator) tells Stanley (the player) what to do; however, Stanley is not
                        obligated to follow these commands. For example, the first command the Narrator gives Stanley
                        is: "When Stanley came to a set of 2 open doors, he entered the door on his left." Ultimately,
                        however, it is up to Stanley to decide whether he enters one of the doors, or not enter at all.
                    </p>
                    <img
                        src={'https://assets.reedpopcdn.com/stanley_parable_ending_37.jpg/BROK/resize/1200x1200%3E/format/jpg/quality/70/stanley_parable_ending_37.jpg'}
                        alt={'First two doors which is asked Stanley to decide.'}></img>
                    <ArticleHeader header={'A Door'}
                                   id={'third-header'}/>
                    <p>
                        The beautiful thing about this game is that whatever you select, it changes the future of the
                        game. This works similarly in a regular story-based game, as the story evolves based on your
                        choices. However, when playing this game, you feel as though
                        <span className={'significant_left'}>
                        the choices does not truly belong to you.</span>
                        <br/> You remember the doors that the narrator commanded you to enter to the left, but you
                        choose the room on the right. When you enter, the narrator makes it clear that you chose the wrong room, even
                        though you knew the door on the left was the correct one (in an offensive way). Perhaps you
                        wanted to see what was in the room on the right. Sometimes the narrator lets you do what you want,
                        sometimes not. Pretty much game progress in this manner. You become
                        the Stanley to find his (yours?) identity.
                    </p>
                    <img
                        src={'https://oyster.ignimgs.com/mediawiki/apis.ign.com/the-stanley-parable/5/5e/MeetingRoom.jpg?width=1280'}
                        alt={'The conference room'} width={'100%'}/>
                    <ArticleHeader header={'A Stanley'}
                                   id={'fourth-header'}/>
                    <p>
                        The reason of this explanation is, nowadays I feel like I am a Stanley trying to do the right
                        thing under the voices of many narrators. Argghhhh!

                    </p>
                    <p>
                        When I went to buy shoes that I really wanted, one voice tells me they’re ugly,
                    </p>
                    <p>

                        When I want to talk to people I like, another says I'm pathetic,
                    </p>
                    <p>
                        And when I want to live by myself, yet another says I'm in some kind of game and I
                        should act accordingly.
                    </p>
                    <p>
                        No, I just can’t.

                        Don’t get me wrong I am not saying rules should be all ignored. But narrator has no rights to
                        dictate my behaviours.
                    </p>

                    <ArticleHeader header={'A Narrator'}
                                   id={'fifth-header'}/>
                    <p>
                        Narrators can be found everywhere - in the workplace, in the home, in the holy sky and even in
                        the depths of our own minds. They tell us what to do or what we are doing wrong. Because this is
                        all they can do. They have no power but to get inside our heads. We can choose to listen
                        to them, and may even find value in their saying, but we must remember that they are merely
                        spectators, not the players. It is up to us to make our own choices and forge our own paths.</p>
                    <img src={'https://www.stanleyparable.com/assets/images/ud_screenshot1.jpg'}
                         alt={'Stanley Parable'} width={'100%'} loading={"lazy"}/>

                </div>
                </div>
            </div>
        </div>
    );
};
