import React from 'react';

import {ThemeContext} from '../../utils/ThemeContext';

const DarkToggle = () => {
    const {colorMode, setColorMode} = React.useContext(ThemeContext);


    return (
        <input id="toggle" className="darkToggle"
               checked={colorMode === 'dark'}
               onChange={ev => {
                   setColorMode(ev.target.checked ? 'dark' : 'light');
               }} type="checkbox"/>
    );
};

export default DarkToggle;
