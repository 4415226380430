/* eslint-disable import/no-webpack-loader-syntax */
import {useParams} from 'react-router-dom';
// import Writing from './writing';
import {Marble} from './writings/marble';
import {Stanley} from './writings/stanley';

//TODO: Create a writing base
const WritingRouter = () => {
    let params = useParams();
    console.log(params.writingName);
    // eslint-disable-next-line default-case
    switch (params.writingName) {
        case '1':
            return null;
        case 'perceptionist-marble':
            return <Marble/>;
        case 'stanley':
            return <Stanley/>
    }
};
export default WritingRouter;
