/* eslint-disable import/no-webpack-loader-syntax */
import '../writing.css';
import MainHeader from '../components/main-header/MainHeader';
import Abstract from '../components/abstract/Abstract';
import TableOfContents from '../components/table-of-contents';
import ArticleHeader from '../components/article-header/ArticleHeader';
import useDocumentTitle from '../utils/useDocumentTitle';
import About from '../components/about/About';

//TODO: Create a writing base
export const Marble = () => {
    useDocumentTitle('Marble');
    return (
        <div className="container" >
            <About/>
            <div className="content">
                <MainHeader datetime={'22 May, 2022'} header="A marble changed my perception" color={'#BA0021'}/>
                <Abstract
                    text={'Why marble is always colder or hotter than any other material its around?'}/>
                <TableOfContents cssClass={'activeWritingHead'}/>

                <div className="writing">
                    <ArticleHeader header={'I forgot my keys inside'}
                                   id={'second-header'}/>
                    <p>
In my city, winters are typically colder compared to the neighboring cities due to its location surrounded by mountains.
This creates isolated weather conditions and leads to significantly low humidity levels. 
As a result, the air is unable to regulate the temperature without moisture, resulting in extremely hot summers and extremely cold winters.
                    </p>
                    <p>
                        I was back from shopping, standing in front of my door, searching for the keys. And then I
                        realized,
                    didn’t take them with me, so I called my sister to bring hers.
                </p>

                <p>She said, “I’ll be there in 20 mins.”</p>
                <ArticleHeader header={'I sat on a marble'}
                               id={'third-header'}/>
                <p>
                    20 minutes is not that long right, oh it is long in freezing cold (I remember that it was below -5
                    Celsius).
                    I was kinda tired and sat on the marble stairs at the entrance of my house.
                    This is where I felt the coldness of the marble. I was able to sit there only 30 seconds before
                    getting sick.
                </p>
                <p>
                    With the boredom of waiting, I googled “Why marble is colder than wood" (my door handle was made of
                    wood).
                </p>
                <p>
                    It’s not. In fact, marble and wood have the same temperature as the weather.
                </p>
                    <span className="significant_left">But how?</span>
                    <p>I could feel that my door handle was so much warmer than the stairs. Since I could’ve held the
                        handle for at least 5 min but can’t sit on the marble for 30 secs.</p>
                    <p>
                        It wasn’t, I was just feeling that it was.
                    </p>
                <ArticleHeader header={'It feels so cold'}
                               id={'fourth-header'}/>
                <p>
                    When you touch a surface, that surface and your hand try to balance their heat (energies) by giving
                    or taking some between them. In our case, it was marble and my hand. It feels so cold because a marble conducts heat faster then wood. It took the heat from my hand so quickly that I thought marble was colder
                    than wood.</p>
                <ArticleHeader header={'It\'s not you it\'s me'}
                               id={'fifth-header'}/>
                <p>
                    I did a mistake in the first place by thinking marble is so cold. No, it moved the heat from my hand
                    too effectively and had me feel the coldness so good that I thought it was extremely cold. It just
                    helped (caused) me feel the weather better than wood.
                </p>
                    <span className="significant_left">It was just my impression.</span>
                    <p>I approach things more calmly from then. I listen, watch, and feel things or people more
                        carefully before I construct a judgement on them. I try to take things as they are, not as I see them.
                    </p>
                </div>
            </div>
        </div>
    );
};
