import React from 'react';
import './About.scss';
import DarkToggle from '../dark-toggle/DarkToggle';


class About extends React.Component {
    render() {
        return (
            <div className="about">
                <div className="nameContainer" onClick={() => {
                    window.location.href = '/';
                }}>
                    <a href={"#go-to-home"} className={'name'}>Yunus Can SAKALLI</a>
                </div>

                <div className="links">
                    <a className="github_link" href="https://github.com/adamsocrat" target="_blank"
                       rel="noreferrer">Github</a>
                    <a className="mail" href="mailto:ycsakalli@gmail.com" target="_blank" rel="noreferrer">Mail</a>
                    <a className="spotify" href="https://open.spotify.com/user/zonuscan?si=6090f7f403f24d4d"
                       target="_blank" rel="noreferrer">Spotify</a>
                    <a className={'cv'}
                       href={'https://drive.google.com/file/d/1R36E6TiamtLbkJ2H2YatduG2qgoaICUM/view?usp=sharing'}
                       target={'_blank'} rel={'noreferrer'}>CV</a>
                    <DarkToggle/>
                </div>
            </div>
        );
    }
}

export default About;
