import React from 'react';
import './MainHeader.css';

const MainHeader = (props) => (
    <h1 className="header" id="initial-header">
        <mark style={{
            backgroundColor: props.color,
        }}>
            {props.header}
        </mark>
        <p className={'datetime'}>
            <time dateTime={Date.parse(props.datetime).toString()}>
                {props.datetime}</time>
        </p>
    </h1>
);

export default MainHeader;
