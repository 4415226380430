const MakaleItem = (props) => (
    <div className="item" style={{
        borderColor: '#f0c808',
    }}>
        <p className="item_title">
            <a
                style={{
                    textTransform: 'capitalize',
                }}
                href={props.url} target="_blank" rel="noreferrer">
                {props.title}
            </a>
        </p>
    </div>
);

export default MakaleItem;
