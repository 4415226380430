import {createGlobalStyle} from 'styled-components';

const GlobalStyles = createGlobalStyle`

  * {
    line-height: normal;
    letter-spacing: -0.003em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
  }

  html {
    tab-size: 4;
    height: 100%;
  }

  body {
    background: var(--color-background);
    color: var(--color-text);
    transition: background 0.35s ease-in-out, color 0.35s ease-in-out;
    margin: 0;
    font-family: 'DM', monospace !important;
  }

  a {
    color: var(--color-secondary);
    text-decoration: none;
  }

  :root {
    --color-background: hsl(0, 0%, 9%);
    --color-text: hsl(216, 12.2%, 83.9%);
    --red: #BA0021;
    --space: 0.625rem;
    --col-space: 4vw;
    --link-inside-padding: 0.125rem;
    --inside-padding: 0.8rem;
  }

  ::selection {
    color: var(--color-background);
    background: var(--color-text);
  }

  .container {
    padding: var(--col-space);
  }
`;

export default GlobalStyles;
