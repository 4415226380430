import React from 'react';
import './ArticleItem.css';
import {Link} from 'react-router-dom';

const ArticleItem = (props) => (
    <div className="item" style={{
        borderColor: "#BA0021",
    }}>
        <p className="item_title">
            <Link to={props.link}>
                {props.title}
            </Link>
            <p className="item_date">Date: {props.date}</p>
        </p>
    </div>
);

export default ArticleItem;
