import './Home.css';
import ArticleItem from './components/home-article-item/ArticleItem';
import PaperItem from './components/paper-item/PaperItem';
import MakaleItem from './components/makale-item';
import About from './components/about/About';
import React from 'react';
import {ThemeContext} from './utils/ThemeContext';

const Home = () => {
    const {colorMode} = React.useContext(ThemeContext);

    return (
        <div className="container">
            <About/>
            <img className="ayemyunus" style={{
                filter: colorMode === 'dark' ? 'invert(100%)' : 'invert(0%)',
                opacity: colorMode === 'dark' ? '0.82' : '1',
            }}
                 src={process.env.PUBLIC_URL + '/qqqqd.svg'} alt={'Sometimes I write'}
            />

            <div className={'listMembrane'}>
                <div className="list">
                    <h2 className="wtitle">Writings</h2>
                    <ArticleItem
                        title={'A marble changed my perception'}
                        link={'/writings/perceptionist-marble'}
                        date={'22 May 2022'}
                    />
                    <ArticleItem
                        title={'Stanley'}
                        link={'/writings/stanley'}
                        date={'21 November 2022'}
                    />


                </div>
                <div className="list">
                    <h2 className="metitle">Papers, Books</h2>
                    <PaperItem
                        url={'https://drive.google.com/file/d/1Puy2F56n7shhquZbd8cAZ-coKBVnbA5J/view?usp=share_link'}
                        author={'Me, Myself and I'}
                        title={'Blockchain within International Supply Chain: A Chance for More Robustness?'}
                        type={'Research Paper'}
                    />
                    <PaperItem url={'https://www.amazon.com/Principles-Neural-Science-Sixth-Kandel-ebook/dp/B08LNXDCS3'}
                               author={'Eric R. Kandel et.al'}
                               title={'Principles of Neural Science Sixth Edition'}
                               type={'Text Book'}
                    />
                    <PaperItem url={'https://bitcoin.org/bitcoin.pdf'}
                               author={'Satoshi Nakamoto'}
                               title={'Bitcoin'}
                               type={'White Paper'}/>


                    <PaperItem url={'https://www.gutenberg.org/files/3600/3600-h/3600-h.htm'}
                               author={'Michel de Montaigne'}
                               title={'The Essays'}
                               type={'Book'}/>
                </div>
                <div className="list">
                    <h2 className="stitle">Articles, Posts</h2>
                    <MakaleItem url={'https://www.quantamagazine.org/new-brain-maps-can-predict-behaviors-20211206/'}
                                title={'New Brain Maps Can Predict Behaviors'}/>
                    <MakaleItem url={'https://www.lesswrong.com/posts/7hFeMWC6Y5eaSixbD/100-tips-for-a-better-life'}
                                title={'100 Tips for a Better Life'}/>
                    <MakaleItem url={'https://www.nature.com/articles/d41586-019-02211-5'}
                                title={'The forgotten part of memory'}/>
                </div>
            </div>
            {/*<Footer/>*/}
        </div>
    );
};

export default Home;
