import React from 'react';
import './ArticleHeader.css';

const ArticleHeader = (props) => (
    <h2 className="article-title" id={props.id}>
        {props.header}
    </h2>
);

export default ArticleHeader;
