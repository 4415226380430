import './TableOfContents.css';
import React from 'react';
import {useIntersectionObserver, useHeadingsData} from './utils';


const Headings = ({cssClass, headings, activeId}) => (
    <ul>
        {headings.map((heading) => (
            <li key={heading.id} className={heading.id === activeId ? cssClass : ''}>
                <a
                    href={`#${heading.id}`}
                    onClick={(e) => {
                        e.preventDefault();
                        document.querySelector(`#${heading.id}`).scrollIntoView({
                                                                                    behavior: 'smooth',
                                                                                });
                    }}
                >
                    {heading.title}
                </a>
                {heading.items.length > 0 && (
                    <ul>
                        {heading.items.map((child) => (
                            <li
                                key={child.id}
                                className={child.id === activeId ? cssClass : ''}
                            >
                                <a
                                    href={`#${child.id}`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        document.querySelector(`#${child.id}`).scrollIntoView({
                                                                                                  behavior: 'smooth',
                                                                                              });
                                    }}
                                >
                                    {child.title}
                                </a>
                            </li>
                        ))}
                    </ul>
                )}
            </li>
        ))}
    </ul>
);

const TableOfContents = (props) => {
    const [activeId, setActiveId] = React.useState();
    const {nestedHeadings} = useHeadingsData();
    useIntersectionObserver(setActiveId);

    return (
        <nav aria-label="Table of contents">
            <Headings cssClass={props.cssClass} headings={nestedHeadings} activeId={activeId}/>
        </nav>
    );
};

export default TableOfContents;
