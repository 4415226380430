const PaperItem = (props) => (
    <div className="item" style={{
        borderColor: '#32e875',
    }}>
        <p className="item_title">
            <a href={props.url} target="_blank" rel="noreferrer">{props.title}</a>
            <br/>
            <p style={{
                fontSize: 'smaller',
                fontWeight: 'normal',
            }}>
                Author: {props.author}<br/>
                Type: {props.type}</p>

        </p>
    </div>
);

export default PaperItem;
