import React from 'react';
import './Abstract.css';

const Abstract = (props) => (
    <div className="abstract">
        <p>{props.text}</p>
    </div>
);

export default Abstract;
